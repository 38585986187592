import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import Header from "../../components/Header/Header";
import config from "../../config/config.json";
import {Client} from "../../models/ClientsConfig";
import './Chat.css';
import ChatInput from "../../components/ChatInput/ChatInput";
import ChatMessage from "../../components/ChatMessage/ChatMessage";
import ChatUserForm from "../../components/ChatUserForm/ChatUserForm";
import {WebSocketService} from "../../services/Websocket";
import {IMessage} from "../../models/IMessage";
import { saveAs } from 'file-saver';
import { Helmet } from 'react-helmet';
import ChatUserAppointment, {ChatUserAppointmentData} from "../../components/ChatUserAppointment/ChatUserAppointment";
import ChatBailleurForm from "../../components/ChatBailleurForm/ChatBailleurForm";
import {FaThumbsDown, FaThumbsUp} from "react-icons/fa6";
import { Buffer } from 'buffer/';

export type ChatProps = {}

export type ChatSteps = "chat" | "form" | "appointment" | "end" | "end_not_found";

export default function Chat({}: ChatProps) {
  const { param } = useParams<{ param: string }>();
  const [client, setClient] = React.useState<Client>(config?.clients[0]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isFormDisabled, setIsFormDisabled] = React.useState(false);
  const [isChatInputDisabled, setIsChatInputDisabled] = React.useState(true);
  const [bailleur, setBailleur] = React.useState<string | undefined>(undefined);
  const [messages, setMessages] = React.useState<IMessage[]>([]);
  const [socket, setSocket] = React.useState<WebSocketService | undefined>(undefined);
  const [step, setStep] = React.useState<ChatSteps>("chat");
  const [isBotWriting, setIsBotWriting] = React.useState(false);
  const [ratingSent, setRatingSent] = React.useState(false);
  const [appointmentData, setAppointmentData] = React.useState<ChatUserAppointmentData | undefined>(undefined);
  const [disableAppointmentForm, setDisableAppointmentForm] = useState<boolean>(false);
  const [urlUserFormData, setUrlUserFormData] = useState<{ [key: string]: string } | undefined>(undefined);
  const [placeholders, setPlaceholders] = useState<{}>({});

  function replacePlaceholders(text: string, variables: { [key: string]: any }): string {
    return text.replace(/{{(.*?)}}/g, (_, key) => variables[key.trim()] || '');
  }

  const welcomeMessage = function () {
    console.log("Calling welcomeMessage with placeholders", placeholders);
    return replacePlaceholders(client.welcome_message, placeholders);
  }

  let init = false;

  useEffect(() => {
    if (init) return;
    init = true;
    const param_client = config.clients.find((client: Client) => client.slug === param);

    if (param_client) {
      setClient(param_client);

      setIsChatInputDisabled(!param_client.bailleur);
      if (param_client.bailleur) {
        handleBailleurFormSubmit(param_client.slug, false);
      }
    }

    //GET identity from URL GET parameters
    const urlParams = new URLSearchParams(window.location.search);
    const identity = urlParams.get('identity');
    if (!identity) return;

    // identity from base64 to text, then from text to JSON
    const identityText = Buffer.from(identity, 'base64').toString();
    const identityJSON = JSON.parse(identityText);
    setUrlUserFormData(identityJSON);

    setPlaceholders({
      client_description: client.description,
      client_name: client.name,
      user_name: identityJSON.n,
      user_lastname: identityJSON.ln,
      user_email: identityJSON.e,
      user_address: identityJSON.a1,
      user_addressSecondLine: identityJSON.a2,
      user_postalCode: identityJSON.cp,
      user_city: identityJSON.v
    })
    console.log(placeholders)
  }, [ param ])

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({behavior: 'smooth'});
    }
  });

  const handleBailleurFormSubmit = (bailleur: string, sendMessage: boolean = true) => {
    setBailleur(bailleur);
    let newSocket = new WebSocketService(config.backend_url + `/ws`);
    setSocket(newSocket);
    newSocket!.start();
    setIsChatInputDisabled(false);

    if (sendMessage) {
      setMessages([
        {
          id: "welcome",
          content: "Merci d'avoir rempli le formulaire, cette information sera prise en compte lors de la prise de rendez-vous.\nPouvez-vous me décrire le problème ou le besoin que vous rencontrez actuellement dans votre logement ?",
          bot: true,
          timestamp: Date.now()
        }
      ]);
    }

    newSocket!.onError(() => {
      setIsLoading(false);
      setIsError(true);
    });

    newSocket!.onMessage(
      (message: IMessage | undefined, end: boolean) => {
        console.log("onMessage isBotWriting set to", !end);
        setIsBotWriting(end);

        if (message !== undefined) {
          setMessages((prevMessages) => {
            const lastMessage = prevMessages.find((m) => m.id === message.id);
            if (lastMessage) {
              return prevMessages.map((m) =>
                m.id === message.id ? { ...m, content: m.content + message.content } : m
              );
            } else {
              return [...prevMessages, message];
            }
          });
        }
      },
      (data) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: "appointment",
            content: "Très bien, votre situation nécessite l'intervention d'un technicien qualifié à votre logement. Pour cela, je vais vous demander de bien vouloir renseigner vos disponibilités pour un rendez-vous.",
            bot: true,
            timestamp: Date.now()
          }
        ]);

        setStep("appointment");
      },
      (found) => {
        if (!found.is_found) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              id: "end_not_found",
              content: "Logista n'intervient pas pour cette prestation, je vous invite à vous rapprocher de votre bailleur. Merci à vous.",
              bot: true,
              timestamp: Date.now()
            }
          ]);
          setStep("end_not_found")
        }
      }
    );
  };

  const handleUserFormSubmit = (data: any) => {
    console.log(data);

    socket!.sendMessage(JSON.stringify({
      type: 'FormContent',
      form_content: {...data, bailleur: bailleur},
      appointment_availability: appointmentData
    }));

    setIsFormDisabled(true);
    setStep("end");
  }

  const handleUserAppointmentSubmit = (data: ChatUserAppointmentData) => {
    setAppointmentData(data);
    setDisableAppointmentForm(true);
    setStep("form");
  }

  const onTranscriptSave = () => {
    const transcript = messages.map((message) => {
      const sender = message.bot ? 'Chatbot' : 'Utilisateur';
      return `${sender}\n${message.content}`;
    }).join('\n\n');

    const blob = new Blob([transcript], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'transcript.txt');
  };

  const handleChatInputSubmit = async (message: string) => {
    if (isBotWriting || message.trim() === "") {
      return;
    }
    setIsBotWriting(true);

    // Update the messages state using setMessages (this will trigger a re-render)
    setMessages((prevMessages) => [
      ...prevMessages,
      { content: message, bot: false, timestamp: Date.now() }
    ]);

    // Send the message after the state has been updated
    socket!.sendMessage(message);
  };

  const handleRating = (up: boolean) => {
    if (ratingSent) {
      return;
    }
    socket!.sendMessage(JSON.stringify({
      type: up ? 'PositiveScore' : 'NegativeScore',
    }));
    setRatingSent(true);
    socket?.socket?.close()
  }

  return (
    <div id="chat" className={param}>
        <Helmet>
          <title>Assistance - {client.name} - {step}</title>
          <link rel="icon" type="image/png" href={client.logo} />
        </Helmet>
        { client.header && (
          <Header client={client}/>
        )}
        <div id={"chat-content"}>
          <div className={"chat-message-holder"}>
            <div className={"chat-message-box"}>
              <div className={"logo"}>
                <img src={client.logo} alt="logo"/>
              </div>
              <div className={"chat-message"}>
                <div className={"chat-message-content"}>
                  <p dangerouslySetInnerHTML={{ __html: welcomeMessage().replace(/\n/g, '<br>') }}></p>
                </div>
              </div>
            </div>
            <h3 className={"chat-message-sender"}>{client.name}</h3>
          </div>
          {!client.bailleur && (
            <ChatBailleurForm onSubmit={handleBailleurFormSubmit}/>
          )}
          {messages.map((message, index) => (
            <ChatMessage key={index} client={client} from_bot={message.bot ?? false} message={message.content} timestamp={message.timestamp}/>
          ))}
          {(step !== "chat" && step !== "end_not_found") && (
            <ChatUserAppointment onSubmit={handleUserAppointmentSubmit} disableAppointmentForm={disableAppointmentForm} />
          )}
          {(step === "form" || step === "end") && (
            <>
              <div className={"chat-message-holder"}>
                <div className={"chat-message-box"}>
                  <div className={"logo"}>
                    <img src={client.logo} alt="logo"/>
                  </div>
                  <div className={"chat-message"}>
                    <div className={"chat-message-content"}>
                      <p>Merci beaucoup, nous prendrons note de ces disponibilités pour établir votre date et heure de rendez-vous. Pour pouvoir confirmer avec vous ces horaires ainsi que votre prise en charge, pouvez-vous saisir vos coordonnées ?</p>
                    </div>
                  </div>
                </div>
                <h3 className={"chat-message-sender"}>{client.name}</h3>
              </div>
              <ChatUserForm onSubmit={handleUserFormSubmit} disabled={isFormDisabled} urlUserFormData={urlUserFormData} onBack={() => {
                setStep("appointment");
                setDisableAppointmentForm(false);
              }}/>
            </>
          )}
          {(step === "end" || step === "end_not_found") && (
            <>
              {step === "end" && (
                <div className={"chat-message-holder"}>
                  <div className={"chat-message-box"}>
                    <div className={"logo"}>
                      <img src={client.logo} alt="logo"/>
                    </div>
                    <div className={"chat-message"}>
                      <div className={"chat-message-content"}>
                        <p>Merci beaucoup. Votre demande de rendez-vous avec un technicien a bien été prise en compte.
                          Vous serez recontacté sous peu pour vous informer de la date et de l'heure du rendez-vous.</p>
                        {false && (
                          <>
                            <br/>
                            <p>Données brutes sur le quoi retrouvé: </p>
                            <ul>
                              {Object.entries([]).map(([key, value]) => {
                                const mapping = {
                                  "id": "Numéro unique de Quoi",
                                  "category": "Catégorie du Quoi",
                                  "item": "Objet concerné",
                                  "problem": "Problème rencontré",
                                };
                                return (
                                  <li key={key}>
                                    <strong> - {mapping[key as keyof typeof mapping]}:</strong> {value ?? "N/A"}
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <h3 className={"chat-message-sender"}>{client.name}</h3>
                </div>
              )}
              <div className={"review"}>
                <p>Avez-vous apprécié cet échange ?</p>
                <div className={"ratings"}>
                  <FaThumbsUp className={"text-4xl up"} onClick={() => handleRating(true)}/>
                  <FaThumbsDown className={"text-4xl down"} onClick={() => handleRating(false)}/>
                </div>
                {ratingSent && (
                  <p className={"text-xs text-center pt-4"}>Merci pour votre retour !</p>
                )}
              </div>
            </>
          )}
          <div ref={divRef} style={{float: 'left', clear: 'both'}}/>
        </div>
      {step === "chat" &&
        <ChatInput onSubmit={handleChatInputSubmit} onTranscriptSave={onTranscriptSave} disabled={isChatInputDisabled}/>
      }
    </div>
  );
}
