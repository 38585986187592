export default function formatTime(timestamp: number): string {
  const now = Date.now();
  const diff = Math.floor((now - timestamp) / 1000); // difference in seconds

  if (diff < 20) {
    return `À l'instant`;
  } else if (diff < 60) {
    return `Il y a moins d'une minute`;
  } else if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    if (minutes === 1) {
      return `Il y a une minute`;
    }
    return `Il y a ${minutes} minutes`;
  } else {
    const hours = Math.floor(diff / 3600);
    if (hours === 1) {
      return `Il y a une heure`;
    }
    return `Il y a ${hours} heures`;
  }
}