import React from 'react';
import {Client} from "../../models/ClientsConfig";

export type HeaderProps = {
    client: Client;
}

export default function Header({client}: HeaderProps) {
    return (
      <div id="header" className={""}>
          <div className={"logo"}>
            <img src={client.logo} alt="logo"/>
          </div>
          <div className={"client-data"}>
              <h1 id={"title"}>{client.name}</h1>
              <h2 id={"description"}>{client.description}</h2>
          </div>
      </div>
    );
}
