import React from 'react';
import config from "../../config/config.json";
import Select, {StylesConfig} from "react-select";


export type ChatBailleurFormProps = {
  onSubmit: (bailleur: ChatBailleur) => void;
}

export type ChatBailleur = string;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const slugs = config.clients.filter(client => client.bailleur).map(client => client.slug);
const options = config.clients.filter(client => client.bailleur).map(client => client.slug).map(slug => ({
  value: slug,
  label: capitalizeFirstLetter(slug)
}));


export default function ChatBailleurForm(props: ChatBailleurFormProps) {

  const [bailleur, setBailleur] = React.useState<ChatBailleur | undefined>(undefined);
  const [disabled, setDisabled] = React.useState<boolean>(false);

  type OptionType = {
    value: string;
    label: string;
  };


  const customStyles: StylesConfig<OptionType, false> = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'var(--theme-color)' : provided.backgroundColor, // Replace '#ff6347' with your color
      color: state.isSelected ? 'white' : provided.color, // Ensure contrast
      ':active': {
        backgroundColor: state.isSelected ? 'var(--theme-color)' : provided[':active']?.backgroundColor || provided.backgroundColor,
      },
    }),
    control: (provided) => ({
      ...provided,
      borderColor: 'var(--theme-color)', // Change border color
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'var(--theme-color)', // Maintain color on hover
      },
    }),
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!bailleur) return;
    props.onSubmit(bailleur as ChatBailleur);
    setDisabled(true);
    // Handle form submission
  };

  return (
    <div className={"chat-message-holder sent"}>
      <div className={"chat-message-box"}>
        <div className={`chat-message`}>
          <div className={"chat-message-content"}>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit} className="space-y-2 w-full" id="chat-appointment">
                <p>Mon bailleur est:</p>
                <label htmlFor={"bailleur"} className="font-medium text-gray-700">
                  Bailleur
                </label>
                <Select id={"bailleur"}
                        onChange={(e) => setBailleur(!e ? undefined : e.value as ChatBailleur)}
                        value={options.find(option => option.value === bailleur)}
                        options={options}
                        placeholder={"Aucun"}
                        isSearchable={false}
                        isClearable={true}
                        isDisabled={disabled}
                        className={"text-stone-900 w-48"}
                        styles={customStyles}
                />
                <button type="submit" className={"submit"} disabled={disabled}>
                  Valider
                </button>
              </form>
              {disabled && <div className="appointment-form-mask"></div>}
            </div>
          </div>
        </div>
      </div>
      <h3 className={"chat-message-sender"}>Moi</h3>
    </div>
  );
}
