import React, {useEffect, useState} from 'react';
import {Client} from "../../models/ClientsConfig";
import "../../utils/formatTime";
import formatTime from "../../utils/formatTime";

export type ChatMessageProps = {
    client: Client;
    from_bot: boolean;
    message: string;
    timestamp: number;
}

export default function ChatMessage(props: ChatMessageProps) {
    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTime(Date.now());
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    return (
      <div className={`chat-message-holder ${!props.from_bot && "sent"}`}>
        <div className={"chat-message-box"}>
          {props.from_bot && (
            <div className={"logo"}>
              <img src={props.client.logo} alt="logo"/>
            </div>
          )}
          <div className={"chat-message"}>
            <div className={"chat-message-content"}>
              <p>{props.message}</p>
            </div>
            <div className={"chat-message-time"}>
              <p>{formatTime(props.timestamp)}</p>
            </div>
          </div>
        </div>
        <h3 className={"chat-message-sender"}>{props.from_bot ? props.client.name : "Moi"}</h3>
      </div>
    );
}
