import React, {useRef, useState} from 'react';
import './ChatUserForm.css';
import {FaTriangleExclamation} from "react-icons/fa6";

export type ChatUserFormProps = {
  onSubmit: (formData: {
    name: string;
    lastname: string;
    email: string;
    address: string;
    addressSecondLine: string;
    postalCode: string;
    city: string;
  }) => void,
  disabled: boolean,
  onBack: () => void
}

export default function ChatUserForm({onSubmit, disabled, onBack}: ChatUserFormProps) {
  const nameRef = useRef<HTMLInputElement>(null);
  const lastnameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const addressSecondLineRef = useRef<HTMLInputElement>(null);
  const postalCodeRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (disabled) return;

    const name = nameRef.current?.value || '';
    const lastname = lastnameRef.current?.value || '';
    const email = emailRef.current?.value || '';
    const address = addressRef.current?.value || '';
    const addressSecondLine = addressSecondLineRef.current?.value || '';
    const postalCode = postalCodeRef.current?.value || '';
    const city = cityRef.current?.value || '';

    if (!name || !lastname || !email || !address || !postalCode || !city) {
      setError('Tous les champs marqués d\'un "*" sont obligatoires.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Adresse email invalide.');
      return;
    }

    if (postalCode.length !== 5 || !/^\d{5}$/.test(postalCode)) {
      setError('Code postal invalide.');
      return;
    }

    setError(null);
    onSubmit({name, lastname, email, address, addressSecondLine, postalCode, city});
  };

  return (
    <div className={"chat-message-holder sent"}>
      <div className={"chat-message-box"}>
        <div className={`chat-message`}>
          <div className={"chat-message-content"}>
            <div className="form-wrapper">
              <form className="space-y-2.5" onSubmit={handleSubmit} id={"chat-user-form"}>
                <h1>À propos de vous</h1>
                <div className="grid grid-cols-2 gap-2.5">
                  <div>
                    <label htmlFor="name">Nom*</label>
                    <input type="text" id="name" name="name" ref={nameRef} disabled={disabled}/>
                  </div>
                  <div>
                    <label htmlFor="lastname">Prénom*</label>
                    <input type="text" id="lastname" name="lastname" ref={lastnameRef} disabled={disabled}/>
                  </div>
                </div>
                <div>
                  <label htmlFor="email">Email*</label>
                  <input type="email" id="email" name="email" ref={emailRef} disabled={disabled}/>
                </div>
                <div>
                  <label htmlFor="address">Numéro et voie*</label>
                  <input type="text" id="address" name="address" ref={addressRef} disabled={disabled}/>
                </div>
                <div>
                  <label htmlFor="addressSecondLine">Appartement, étage, etc.</label>
                  <input type="text" id="addressSecondLine" name="addressSecondLine" ref={addressSecondLineRef}
                         disabled={disabled}/>
                </div>
                <div className="grid grid-cols-2 gap-2.5">
                  <div>
                    <label htmlFor="postalCode">Code Postal*</label>
                    <input type="text" id="postalCode" name="postalCode" ref={postalCodeRef} disabled={disabled}/>
                  </div>
                  <div>
                    <label htmlFor="city">Ville*</label>
                    <input type="text" id="city" name="city" ref={cityRef} disabled={disabled}/>
                  </div>
                </div>
                {error && (
                  <div className="text-white text-sm flex items-center">
                    <FaTriangleExclamation className="mr-1"/> {error}
                  </div>
                )}
                <div>
                  <button type="submit" className="submit" disabled={disabled}>
                    Soumettre
                  </button>
                </div>
                <div>
                  <button type="button" className="submit" disabled={disabled} onClick={onBack}>
                    Revenir en arrière
                  </button>
                </div>
              </form>
              {disabled && <div className="form-mask"></div>}
            </div>
          </div>
        </div>
      </div>
      <h3 className={"chat-message-sender"}>Moi</h3>
    </div>
  );
}
