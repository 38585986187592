import React, {useEffect, useState} from 'react';
import './index.css';
import Chat from "./routes/Chat/Chat";
import {BrowserRouter as Router, Route, Navigate, Routes} from "react-router-dom";
import Auth from "./Auth";

function App() {
  // App.tsx
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(storedAuth === 'true'); // Check localStorage for authentication status
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated'); // Clear the local storage
    setIsAuthenticated(false);
  };

  return (
    <div>
      {isAuthenticated ? (
        <Router>
          <Routes>
            <Route path="/chat" element={<Navigate to="/chat/logista" />} />
            <Route path="/chat/:param" element={<Chat/>} />
            <Route path="*" element={<Navigate to="/chat/logista" />} />
          </Routes>
        </Router>
      ) : (
        <Auth onLogin={() => setIsAuthenticated(true)} />
      )}
    </div>
  );
}

export default App;