import React, {useState} from "react";
import './ChatUserAppointment.css';
import Select, {StylesConfig} from "react-select";

export type ChatUserAppointmentProps = {
  onSubmit: (data: ChatUserAppointmentData) => void,
  disableAppointmentForm: boolean,
}

export type ChatUserAppointmentAvailability = "morning" | "afternoon" | "both";

export type ChatUserAppointmentData = {
  monday: ChatUserAppointmentAvailability | undefined;
  tuesday: ChatUserAppointmentAvailability | undefined;
  wednesday: ChatUserAppointmentAvailability | undefined;
  thursday: ChatUserAppointmentAvailability | undefined;
  friday: ChatUserAppointmentAvailability | undefined;
}

export default function ChatUserAppointment(props: ChatUserAppointmentProps) {
  const [data, setData] = useState<ChatUserAppointmentData>({
    monday: undefined,
    tuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined
  });

  const handleChange = (day: keyof ChatUserAppointmentData, value: ChatUserAppointmentAvailability | null) => {
    setData((prevData) => ({
      ...prevData,
      [day]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.onSubmit(data);
  };

  const daysInFrench = {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi"
  };

  type OptionType = {
    value: string;
    label: string;
  };

  const options: OptionType[] = [
    {value: 'morning', label: 'Le matin'},
    {value: 'afternoon', label: 'L\'après-midi'},
    {value: 'both', label: 'Les deux'}
  ]

  const customStyles: StylesConfig<OptionType, false> = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'var(--theme-color)' : provided.backgroundColor, // Replace '#ff6347' with your color
      color: state.isSelected ? 'white' : provided.color, // Ensure contrast
      ':active': {
        backgroundColor: state.isSelected ? 'var(--theme-color)' : provided[':active']?.backgroundColor || provided.backgroundColor,
      },
    }),
    control: (provided) => ({
      ...provided,
      borderColor: 'var(--theme-color)', // Change border color
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'var(--theme-color)', // Maintain color on hover
      },
    }),
  };

  return (
    <div className={"chat-message-holder sent"}>
      <div className={"chat-message-box"}>
        <div className={`chat-message`}>
          <div className={"chat-message-content"}>
            <div className="form-wrapper">
              <form onSubmit={handleSubmit} className="space-y-2 w-full" id="chat-appointment">
                <p>Je suis disponible:</p>
                {Object.keys(daysInFrench).map((day) => (
                  <div key={day} className="flex flex-col">
                    <label htmlFor={day} className="font-medium text-gray-700">
                      {daysInFrench[day as keyof typeof daysInFrench]}:
                    </label>
                    <Select id={day}
                            value={options.find(option => option.value == data[day as keyof ChatUserAppointmentData])! || ""}
                            onChange={(e) => handleChange(day as keyof ChatUserAppointmentData, e == null ? null : e.value as ChatUserAppointmentAvailability)}
                            options={options}
                            placeholder={"Aucune"}
                            isSearchable={false}
                            isClearable={true}
                            isDisabled={props.disableAppointmentForm}
                            className={"text-stone-900 w-48"}
                            styles={customStyles}
                    />
                  </div>
                ))}
                <button type="submit" className={"submit"} disabled={props.disableAppointmentForm}>
                  Valider
                </button>
              </form>
              {props.disableAppointmentForm && <div className="appointment-form-mask"></div>}
            </div>
          </div>
        </div>
      </div>
      <h3 className={"chat-message-sender"}>Moi</h3>
    </div>
  );
}
