import {IMessage, IWebsocketMessage} from "../models/IMessage";

export class WebSocketService {
  get socket(): WebSocket | undefined {
    return this._socket;
  }
  private _socket: WebSocket | undefined;
  private baseUrl: string;

  lastId = 0;
  isWorking = false;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  start() {
    this._socket = new WebSocket(this.baseUrl);
    console.log("WEBSOCKET STARTED", this._socket);
  }

  sendMessage(content: string) {
    if (!this._socket) {
      return;
    }
    this._socket.send(content);
  }

  onMessage(callback: (message: IMessage | undefined, isWorking: boolean) => void, onAppointmentHours: (data: object) => void, onProblemFound: (found: any) => void) {
    console.log("onMessage", this._socket, this.isWorking);
    if (!this._socket) {
      console.log("No socket");
      return;
    }
    this._socket.addEventListener('message', (event) => {
      const message: IWebsocketMessage = JSON.parse(event.data);

      if (message.type === 'appointment-hours') {
        onAppointmentHours(message.data!);
        this.isWorking = false;
        callback(undefined, this.isWorking)
      } else if (message.type === 'problem-found') {
        onProblemFound(message.data!);
        this.isWorking = false;
        callback(undefined, this.isWorking)
      } else if (message.type === 'AIMessageStart') {
        this.isWorking = true;
        callback(undefined, this.isWorking)

      } else if (message.type === 'AIMessageEnd') {
        this.isWorking = false;
        callback(undefined, this.isWorking)

      } else if (message.type === 'AIMessage') {
        callback({
          id: message.id!,
          content: message.content!,
          bot: true,
          timestamp: Date.now()
        }, this.isWorking)
      }
    });
  }

  onError(callback: () => void) {
    console.log("WEBSOCKET ERROR", this._socket);
    if (!this._socket) {
      return;
    }
    this._socket.addEventListener('error', () => {
      callback();
    });
  }
}