import React, { useState, ChangeEvent, FormEvent } from 'react';
import { FaPaperPlane } from 'react-icons/fa6';
import { FaSave } from 'react-icons/fa';

export type ChatInputProps = {
  onSubmit: (message: string) => void;
  onTranscriptSave: () => void;
  disabled?: boolean;
}

export default function ChatInput({ onSubmit, onTranscriptSave, disabled = false }: ChatInputProps) {
  const [message, setMessage] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!disabled) {
      onSubmit(message);
      setMessage('');
    }
  };

  return (
    <div id="chat-input" className="relative">
      <form onSubmit={handleSubmit} className="chat-input-form">
        <input
          type="text"
          value={message}
          onChange={handleChange}
          placeholder="Envoyer un message"
          className="chat-input"
          disabled={disabled}
        />
        <button type="submit" className="chat-submit-button mr-2" disabled={disabled}>
          <FaPaperPlane className="text-lg" />
        </button>
        <button
          type="button"
          className="flex flex-row items-center gap-1 p-2 hover:bg-stone-200 duration-300 rounded-md"
          onClick={onTranscriptSave}
          disabled={disabled}
        >
          <FaSave />
          <p>Transcription</p>
        </button>
      </form>
      {disabled && <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 z-10 pointer-events-none"></div>}
    </div>
  );
}